import {IStylesGetParam} from '@wix/tpa-settings/dist/src/contexts/StylesContext/StylesContext.types'
import {LIST_LOCATION_AND_DATE_FORMAT, MOBILE_LIST_LAYOUT, WIDGET_TYPE} from '@wix/wix-events-commons-statics'
import stylesParams from './stylesParams'

export const StyleSelectors = (get: IStylesGetParam, mobile: boolean) => {
  const getWidgetType = () => get(stylesParams.widgetType)

  const getMobileLayout = () => get(stylesParams.mobileListLayout)

  const isMultiEventWidget = () => getWidgetType() === WIDGET_TYPE.LIST

  const isMobileCompactLayout = () => isMultiEventWidget() && getMobileLayout() === MOBILE_LIST_LAYOUT.COMPACT

  const isListAdditionalInfoEnabled = () => get(stylesParams.listShowAdditionalInfo)

  const isListFullDateEnabled = () => get(stylesParams.listShowFullDate)

  const isListLocationEnabled = () => get(stylesParams.listShowLocation)

  const isListDescriptionEnabled = () => get(stylesParams.listShowDescription)

  const isListCountdownEnabled = () => get(stylesParams.listShowCountdown)

  const isListSocialShareEnabled = () => get(stylesParams.listShowSocialShare)

  const isListMembersEnabled = () => get(stylesParams.listShowMembers)

  const isListAdditionalComponentsVisible = () => {
    if (mobile && isMobileCompactLayout()) {
      return false
    }
    return (
      isListAdditionalInfoEnabled() &&
      (isListFullDateEnabled() || isListLocationEnabled() || isListDescriptionEnabled() || isListSocialShareEnabled())
    )
  }

  const isListDateEnabled = () => get(stylesParams.listShowDate)

  const isListVenueNameEnabled = () => get(stylesParams.listShowVenueName)

  const isListFullDateOrLocationEnabled = () => isListFullDateEnabled() || isListLocationEnabled()

  const isListImageEnabled = () => get(stylesParams.listShowImage)

  const getListLocationAndDateFormat = () => get(stylesParams.listLocationAndDateFormat)

  const getCardLocationAndDateFormat = () => get(stylesParams.cardLocationAndDateFormat)

  const isSideBySideShortDateLocationVisible = () =>
    getListLocationAndDateFormat() === LIST_LOCATION_AND_DATE_FORMAT.COMPACT &&
    (isListDateEnabled() || isListVenueNameEnabled())

  const isSideBySideFullDateLocationVisible = () =>
    getListLocationAndDateFormat() === LIST_LOCATION_AND_DATE_FORMAT.FULL &&
    (isListFullDateEnabled() || isListLocationEnabled())

  const isCardShortDateLocationVisible = () => getCardLocationAndDateFormat() === LIST_LOCATION_AND_DATE_FORMAT.COMPACT

  const isCardFullDateLocationVisible = () => getCardLocationAndDateFormat() === LIST_LOCATION_AND_DATE_FORMAT.FULL

  const isCardHoveredFullDateLocationVisible = () =>
    isListFullDateOrLocationEnabled() && getCardLocationAndDateFormat() !== LIST_LOCATION_AND_DATE_FORMAT.FULL

  const isListMainTitleEnabled = () => get(stylesParams.listShowMainTitle)

  return {
    isMultiEventWidget,
    isMobileCompactLayout,
    isListAdditionalInfoEnabled,
    isListFullDateEnabled,
    isListLocationEnabled,
    isListDescriptionEnabled,
    isListCountdownEnabled,
    isListSocialShareEnabled,
    isListMembersEnabled,
    isListAdditionalComponentsVisible,
    isListDateEnabled,
    isListVenueNameEnabled,
    isListFullDateOrLocationEnabled,
    isListImageEnabled,
    isSideBySideShortDateLocationVisible,
    isSideBySideFullDateLocationVisible,
    isCardShortDateLocationVisible,
    isCardFullDateLocationVisible,
    isCardHoveredFullDateLocationVisible,
    isListMainTitleEnabled,
    getListLocationAndDateFormat,
  }
}
