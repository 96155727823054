import {IWixWindowViewMode} from '@wix/native-components-infra/dist/es/src/types/types'
import {viewerDataHooks as DH} from '@wix/wix-events-data-hooks'
import classNames from 'classnames'
import React from 'react'
import {DynamicStyle} from '../../../../../commons/components/dynamic-style'
import {ErrorPage} from '../../../../../commons/components/error-page'
import {RuntimeContext} from '../../../../../commons/components/runtime-context/runtime-context'
import {Direction} from '../../../../../commons/constants/html'
import {CLASSIC_PRESET, STYLES_PRESET_V2} from '../../../../../commons/constants/stylePresets'
import {DatesProvider} from '../../../../../commons/hooks/dates'
import {StylesForAllBreakpointsProvider} from '../../../../../commons/hooks/styles-for-all-breakpoints'
import {isMobile, isPreview} from '../../../../../commons/selectors/environment'
import {getDefaultNumbersAndBooleans} from '../../../stylesParams'
import {SettingsUpdatedActionType} from '../../actions/sdk'
import {WidgetActionsContext, WidgetStateContext} from '../../hooks/state-provider'
import {getEvents} from '../../selectors/events'
import {isButtonLayout} from '../../selectors/single-settings'
import {AppLoaded} from '../app-loaded'
import {Layout} from '../layout'
import s from './app.scss'
import {AppProps} from './interfaces'

export class App extends React.Component<AppProps> {
  defaultNumbersAndBooleans = getDefaultNumbersAndBooleans({
    isMobile: isMobile(this.props.state),
    isRTL: this.props.isRTL,
  })

  componentDidMount() {
    if (this.props.sdk?.Wix) {
      this.props.sdk.Wix.addEventListener(this.props.sdk.Wix.Events.SETTINGS_UPDATED, this.updateSettings)
      this.props.sdk.Wix.addEventListener(this.props.sdk.Wix.Events.STYLE_PARAMS_CHANGE, this.updateStyle)
      this.props.sdk.Wix.addEventListener(this.props.sdk.Wix.Events.EDIT_MODE_CHANGE, this.onEditModeChange)
    }
  }

  componentDidUpdate(prevProps: AppProps) {
    const {actions, host, state} = this.props
    if (actions && host && state) {
      if (isPreview(state) && host.formFactor !== prevProps.host.formFactor) {
        actions.setFormFactor(host.formFactor)
      }
    }
  }

  componentWillUnmount() {
    if (this.props.sdk?.Wix) {
      // @ts-expect-error
      this.props.sdk.Wix.removeEventListener(this.props.sdk.Wix.Events.SETTINGS_UPDATED, this.updateSettings)
      // @ts-expect-error
      this.props.sdk.Wix.removeEventListener(this.props.sdk.Wix.Events.STYLE_PARAMS_CHANGE, this.updateStyle)
      // @ts-expect-error
      this.props.sdk.Wix.removeEventListener(this.props.sdk.Wix.Events.EDIT_MODE_CHANGE, this.onEditModeChange)
    }
  }

  updateStyle = styleParams => {
    this.props.actions.updateStyles(styleParams)
  }

  updateSettings = action => {
    switch (action.type) {
      case SettingsUpdatedActionType.NAVIGATE_TO_PAGE:
      case SettingsUpdatedActionType.NAVIGATE_TO_PAGE_PENDING:
        if (action.type === SettingsUpdatedActionType.NAVIGATE_TO_PAGE_PENDING) {
          if (this.props.sdk?.Wix) {
            this.props.sdk.Wix.Data.Public.set(action.type, action.meta.arg, {scope: 'APP'}, undefined, undefined)
          }
        } else {
          if (this.props.sdk?.Wix) {
            this.props.sdk.Wix.Data.Public.set(action.type, action.page, {scope: 'APP'}, undefined, undefined)
          }
        }
        break
      default:
        this.props.actions.updateSettings(action)
    }
  }

  onEditModeChange = () => {
    this.props.actions.setBaseEnvironment()
    this.props.actions.closeListLayoutItems()
  }

  render() {
    if (!this.props.state) {
      return <ErrorPage viewMode={this.props.host.viewMode} />
    }

    const {
      host: {viewMode, style, id},
      actions: {widgetLoaded},
      isRTL,
      pageInfo: {isHomePage},
      state,
      actions,
      stylesV2Enabled,
    } = this.props

    const stylePreset = stylesV2Enabled ? STYLES_PRESET_V2 : CLASSIC_PRESET

    this.props.state.component.settings = {
      ...this.defaultNumbersAndBooleans,
      ...state.component.settings,
      ...style.styleParams.numbers,
      ...style.styleParams.booleans,
    }

    return (
      <RuntimeContext.Provider value={this.props}>
        <WidgetStateContext.Provider value={state}>
          <WidgetActionsContext.Provider value={actions}>
            <DatesProvider dates={this.props.state.dates}>
              <StylesForAllBreakpointsProvider>
                <DynamicStyle namespace={id}>
                  {`
   .${s.root} {
    --eventsCount: ${getEvents(state).length};
  }
  `}
                </DynamicStyle>
                <div
                  id="wix-events-widget"
                  className={classNames(s.root, stylePreset, {
                    [s.buttonOnly]: isButtonLayout(this.props.state.component),
                  })}
                  dir={isRTL ? Direction.RTL : Direction.LTR}
                  data-hook={DH.ROOT_NODE}
                >
                  <AppLoaded
                    host={this.props.host}
                    widgetLoaded={widgetLoaded}
                    viewMode={viewMode as IWixWindowViewMode}
                    isHomePage={isHomePage}
                  />
                  <Layout />
                </div>
              </StylesForAllBreakpointsProvider>
            </DatesProvider>
          </WidgetActionsContext.Provider>
        </WidgetStateContext.Provider>
      </RuntimeContext.Provider>
    )
  }
}
