import {
  BUTTONS_STYLE,
  IMAGE_RATIO,
  IMAGE_SCALING,
  LIST_ALIGNMENT,
  LIST_IMAGE_LAYOUT,
  LIST_LAYOUT,
  LIST_LOCATION_AND_DATE_FORMAT,
} from '@wix/wix-events-commons-statics'
import {CustomCssVarsFn} from '@wix/yoshi-flow-editor'
import {remapColor, remapFont} from '../../commons/custom-css-utils'
import {StyleSelectors} from './style-selectors'
import stylesParams, {WidgetStyleParams} from './stylesParams'

export const customCssVars: CustomCssVarsFn<WidgetStyleParams> = ({styleParams, isMobile: mobile, isRTL: RTL}) => {
  const styles = {
    get: param => {
      return (
        styleParams.booleans[param.key] ??
        styleParams.numbers[param.key] ??
        styleParams.colors[param.key] ??
        styleParams.fonts[param.key]
      )
    },
  }

  const get = param => {
    const key = param.key
    const value = styles.get(param)

    if (mobile) {
      const mobileKey = key + 'Mobile'
      const deprecatedMobileValue = styles.get(mobileKey)

      return value ?? deprecatedMobileValue
    }

    return value
  }

  const styleSelectors = StyleSelectors(get, mobile)

  const getListLayout = () => {
    const listLayout = get(stylesParams.listLayout)
    return listLayout === LIST_LAYOUT.GRID ? LIST_LAYOUT.NEW_GRID : listLayout
  }

  const isHollowButton = (buttonStyle: BUTTONS_STYLE) =>
    [BUTTONS_STYLE.HOLLOW, BUTTONS_STYLE.HOLLOW_ROUNDED].includes(buttonStyle)

  const isRoundedButton = (buttonStyle: BUTTONS_STYLE) =>
    [BUTTONS_STYLE.FULL_ROUNDED, BUTTONS_STYLE.HOLLOW_ROUNDED].includes(buttonStyle)

  const isSingleHollowButton = () => isHollowButton(get(stylesParams.buttonsStyle))

  const isSingleRoundedButton = () => isRoundedButton(get(stylesParams.buttonsStyle))

  const isListHollowButton = () => isHollowButton(get(stylesParams.listButtonStyle))

  const isListRoundedButton = () => isRoundedButton(get(stylesParams.listButtonStyle))

  const isCalendarLayout = () => styleSelectors.isMultiEventWidget() && getListLayout() === LIST_LAYOUT.CALENDAR

  const isTodayButtonHollow = () => isHollowButton(get(stylesParams.todayButtonStyle))

  const isTodayButtonRounded = () => isRoundedButton(get(stylesParams.todayButtonStyle))

  const isCardsLayout = () =>
    styleSelectors.isMultiEventWidget() &&
    (getListLayout() === LIST_LAYOUT.NEW_GRID || getListLayout() === LIST_LAYOUT.GRID)

  const isListLayout = () => styleSelectors.isMultiEventWidget() && getListLayout() === LIST_LAYOUT.LIST

  const isSideBySideLayout = () => getListLayout() === LIST_LAYOUT.SIDE_BY_SIDE

  const isListImageLayoutRight = () => get(stylesParams.listImageLayout) === LIST_IMAGE_LAYOUT.RIGHT
  const isListImageLayoutLeft = () => get(stylesParams.listImageLayout) === LIST_IMAGE_LAYOUT.LEFT

  const getSideBySideEvenImageDirection = () => (isListImageLayoutLeft() ? 'row' : 'row-reverse')

  const getSideBySideOddImageDirection = () => (isListImageLayoutRight() ? 'row-reverse' : 'row')

  const isMultiFitImage = () => get(stylesParams.multiImageScaling) === IMAGE_SCALING.FIT

  const isSingleImageEnabled = () => get(stylesParams.showImage)

  const isSingleFitImage = () => get(stylesParams.imageScaling) === IMAGE_SCALING.FIT

  const getMultiImageRatio = () => {
    if (get(stylesParams.multiImageRatio)) {
      return get(stylesParams.multiImageRatio)
    }

    if (isCardsLayout() && get(stylesParams.imageRatio)) {
      return get(stylesParams.imageRatio)
    }

    return getDefaultMultiImageRatio()
  }

  const isMultiImageSquare = () => getMultiImageRatio() === IMAGE_RATIO['1:1']

  const getDefaultMultiImageRatio = () => {
    if (isCalendarLayout() || (mobile && !isCardsLayout())) {
      return IMAGE_RATIO['16:9']
    }

    return IMAGE_RATIO['1:1']
  }

  const getListShortDateLocationAlignment = () => {
    const alignment = get(stylesParams.listAlignment)

    switch (alignment) {
      case LIST_ALIGNMENT.LEFT:
        return RTL ? 'flex-end' : 'flex-start'
      case LIST_ALIGNMENT.CENTER:
        return 'center'
      case LIST_ALIGNMENT.RIGHT:
        return RTL ? 'flex-start' : 'flex-end'
    }
  }

  const getListTextAlignment = () => {
    const alignment = get(stylesParams.listAlignment)

    switch (alignment) {
      case LIST_ALIGNMENT.LEFT:
        return 'left'
      case LIST_ALIGNMENT.CENTER:
        return 'center'
      case LIST_ALIGNMENT.RIGHT:
        return 'right'
    }
  }

  const isListTextAlignmentLeft = () => get(stylesParams.listAlignment) === LIST_ALIGNMENT.LEFT
  const isListTextAlignmentRight = () => get(stylesParams.listAlignment) === LIST_ALIGNMENT.RIGHT

  const getSingleButtonColor = () => (isSingleHollowButton() ? 'hollowButtonsFontColor' : 'fullButtonsFontColor')

  const getSingleOnImageButtonColor = () => {
    if (isSingleImageEnabled()) {
      return isSingleHollowButton() ? 'onImageHollowButtonsFontColor' : 'onImageFullButtonsFontColor'
    } else {
      return getSingleButtonColor()
    }
  }

  const getSingleButtonBackgroundColor = () => (isSingleHollowButton() ? null : 'buttonsBackgroundColor')

  const getSingleOnImageButtonBackgroundColor = () => {
    if (isSingleImageEnabled()) {
      return isSingleHollowButton() ? null : 'onImageButtonsBackgroundColor'
    } else {
      return getSingleButtonBackgroundColor()
    }
  }

  const getSingleButtonBorderColor = () => (isSingleHollowButton() ? 'buttonsBorderColor' : null)

  const getSingleOnImageButtonBorderColor = () => {
    if (isSingleImageEnabled()) {
      return isSingleHollowButton() ? 'onImageButtonsBorderColor' : null
    } else {
      return getSingleButtonBorderColor()
    }
  }

  const getCalendarEventNameFontSize = () =>
    Number(get(stylesParams.calendarCellEventNameFont).size?.replace('px', '')) || 14

  const getEventSpacing = () => {
    if ((mobile && styleSelectors.isMobileCompactLayout()) || isListLayout()) {
      return get(stylesParams.listStripWidth)
    } else if (isCardsLayout()) {
      return get(stylesParams.cardMargins)
    } else {
      return get(stylesParams.sideBySideLayoutMargins)
    }
  }

  return {
    CARD_MAX_WIDTH: '700',
    CARD_MIN_WIDTH: '200',
    listMainTitleVisibility: styleSelectors.isListMainTitleEnabled() ? 'block' : 'none',
    listFullDateVisibility: styleSelectors.isListFullDateEnabled() ? 'block' : 'none',
    listLocationVisibility: styleSelectors.isListLocationEnabled() ? 'block' : 'none',
    listVenueNameVisibility: styleSelectors.isListVenueNameEnabled() ? 'block' : 'none',
    listDateVisibility: styleSelectors.isListDateEnabled() ? 'block' : 'none',
    listImageVisibility: styleSelectors.isListImageEnabled() ? 'block' : 'none',
    listDescriptionVisibility: styleSelectors.isListDescriptionEnabled() ? 'block' : 'none',
    listCountdownVisibility: styleSelectors.isListCountdownEnabled() ? 'block' : 'none',
    listSocialBarVisibility: styleSelectors.isListSocialShareEnabled() ? 'block' : 'none',
    listMembersVisibility: styleSelectors.isListMembersEnabled() ? 'block' : 'none',
    listSocialBarVisibilityFlex: styleSelectors.isListSocialShareEnabled() ? 'flex' : 'none',
    listShortDateLocationDividerVisibility:
      !styleSelectors.isListDateEnabled() || !styleSelectors.isListVenueNameEnabled() ? 'none' : 'initial',
    listShortDateLocationAlignment: getListShortDateLocationAlignment(),
    listShortDateLocationLocationWidth: styleSelectors.isListDateEnabled() ? '' : '100%',
    listShortDateLocationDateWidth: styleSelectors.isListVenueNameEnabled() ? '80%' : '100%',
    listTextAlignment: getListTextAlignment(),
    listImageAspectRatio: isMultiImageSquare() ? '100' : '56.25',
    listImageIsSquare: isMultiImageSquare() ? '1' : '0',
    listAdditionalInfoVisibility: styleSelectors.isListAdditionalComponentsVisible() ? 'block' : 'none',
    listAdditionalInfoVisibilityArrow: styleSelectors.isListAdditionalComponentsVisible() ? 'inline-flex' : 'none',
    isListTextAlignmentLeft: isListTextAlignmentLeft() ? '1' : '0',
    isListTextAlignmentRight: isListTextAlignmentRight() ? '1' : '0',
    sideBySideRibbonLeftMargin: isListTextAlignmentLeft() ? '12%' : 'auto',
    sideBySideRibbonRightMargin: isListTextAlignmentRight() ? '12%' : 'auto',
    sideBySideImageDirectionEven: getSideBySideEvenImageDirection(),
    sideBySideImageDirectionOdd: getSideBySideOddImageDirection(),
    sideBySideMinimumItemWidth: styleSelectors.isListImageEnabled() ? '480px' : '320px',
    sideBySideTitleBottomMargin:
      (!styleSelectors.isListDescriptionEnabled &&
        styleSelectors.getListLocationAndDateFormat() !== LIST_LOCATION_AND_DATE_FORMAT.FULL) ||
      !styleSelectors.isListFullDateOrLocationEnabled()
        ? '0'
        : '0.5em',
    sideBySideFullDateLocationBottomMargin: !styleSelectors.isListDescriptionEnabled ? '0' : '30px',
    sideBySideShortDateLocationVisibility: styleSelectors.isSideBySideShortDateLocationVisible() ? 'block' : 'none',
    sideBySideFullDateLocationVisibility: styleSelectors.isSideBySideFullDateLocationVisible() ? 'block' : 'none',
    cardShortDateLocationVisibility: styleSelectors.isCardShortDateLocationVisible() ? 'block' : 'none',
    cardFullDateLocationVisibility: styleSelectors.isCardFullDateLocationVisible() ? 'block' : 'none',
    cardHoveredFullDateLocationVisibility: styleSelectors.isCardHoveredFullDateLocationVisible() ? 'block' : 'none',
    listLayoutTitleLocationVisibility: styleSelectors.isListVenueNameEnabled() ? 'block' : 'none',
    listLayoutTitleLocationTitleWidth: styleSelectors.isListVenueNameEnabled() ? '90%' : '100%',
    multiContainImageVisibility: isMultiFitImage() ? 'flex' : 'none',
    multiCoverImageVisibility: isMultiFitImage() ? 'none' : 'flex',
    singleContainImageVisibility: isSingleFitImage() ? 'flex' : 'none',
    singleCoverImageVisibility: isSingleFitImage() ? 'none' : 'flex',
    calendarEventTitleLineClamp: getCalendarEventNameFontSize() < 16 ? '2' : '1',
    eventSpacing: getEventSpacing(),

    // SINGLE LAYOUT button
    ...remapFont('singleButtonFont', isSingleHollowButton() ? 'hollowButtonsFont' : 'fullButtonsFont'),
    ...remapColor('singleButtonColor', getSingleButtonColor()),
    ...remapColor('singleOnImageButtonColor', getSingleOnImageButtonColor()),
    ...remapColor('singleButtonBackgroundColor', getSingleButtonBackgroundColor()),
    ...remapColor('singleOnImageButtonBackgroundColor', getSingleOnImageButtonBackgroundColor()),
    ...remapColor('singleButtonBorderColor', getSingleButtonBorderColor()),
    ...remapColor('singleOnImageButtonBorderColor', getSingleOnImageButtonBorderColor()),
    singleButtonBorderWidth: isSingleHollowButton() ? 'var(--buttonsBorderWidth)' : '0',
    singleButtonBorderRadius: isSingleRoundedButton()
      ? 'var(--roundedButtonsBorderRadius)'
      : 'var(--buttonsBorderRadius)',

    // LIST layouts button
    ...remapFont('multiButtonFont', isListHollowButton() ? 'listHollowButtonFontV2' : 'listFullButtonFontV2'),
    ...remapColor('multiButtonColor', isListHollowButton() ? 'listHollowButtonFontColor' : 'listFullButtonFontColor'),
    ...remapColor('multiButtonBackgroundColor', isListHollowButton() ? null : 'listButtonBackgroundColor'),
    ...remapColor('multiButtonBorderColor', isListHollowButton() ? 'listButtonBorderColor' : null),
    multiButtonBorderWidth: isListHollowButton() ? 'var(--listButtonBorderWidth)' : '0',
    multiButtonBorderRadius: isListRoundedButton()
      ? 'var(--listRoundedButtonBorderRadius)'
      : 'var(--listButtonBorderRadius)',
    ...remapColor('listLoadMoreContainerBackgroundColor', isListLayout() ? null : 'cardBackgroundColor'),
    ...remapColor(
      'listLoadMoreContainerBorderColor',
      (isCardsLayout() || isSideBySideLayout()) && !styleSelectors.isMobileCompactLayout() ? 'cardBorderColor' : null,
    ),
    listLoadMoreContainerBorderWidth:
      (isCardsLayout() || isSideBySideLayout()) && !styleSelectors.isMobileCompactLayout()
        ? 'var(--cardBorderWidth)'
        : '0',
    listLoadMoreMobileFontSize: styleSelectors.isMobileCompactLayout()
      ? 'var(--listButtonFontSizeCompactMobile)'
      : 'var(--listButtonFontSizeMobile)',

    // CALENDAR TODAY button
    ...remapFont('todayButtonResolvedFont', isTodayButtonHollow() ? 'todayHollowButtonFont' : 'todayButtonFont'),
    ...remapColor('todayButtonResolvedColor', isTodayButtonHollow() ? 'todayHollowButtonColor' : 'todayButtonColor'),
    ...remapColor('todayButtonResolvedBackgroundColor', isTodayButtonHollow() ? null : 'todayButtonBackgroundColor'),
    ...remapColor('todayButtonResolvedBorderColor', isTodayButtonHollow() ? 'todayButtonBorderColor' : null),
    todayButtonResolvedBorderWidth: isTodayButtonHollow() ? 'var(--todayButtonBorderWidth)' : '0',
    todayButtonResolvedBorderRadius: isTodayButtonRounded()
      ? 'var(--todayRoundedButtonBorderRadius)'
      : 'var(--todayButtonBorderRadius)',
  }
}
